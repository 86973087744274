<template>
  <div class="app-body">
    <app-sidebar type="home"></app-sidebar>
    <div class="app-main">
      <app-toolbar
        v-on:showPageSet="showPageSet"
        v-on:savePageSet="savePageSet"
        v-on:showPreview="showPreview"
      ></app-toolbar>
      <div class="scroll-y">
        <div
          class="app-phone"
          @dragover.self="dragPhoneOver"
          @dragleave.self="dragPhoneLeave"
          @drop.self="dropPhone"
          @dragover.prevent
        >
          <template v-for="(comp, idx) in compList">
            <!--占位提示控件-->
            <div
              v-if="comp.type === 'placeholder'"
              class="place-holder"
              @dragover.stop="dragover"
              @dragleave.stop="dragleave"
              @drop.stop="drop"
              @dragover.prevent
              :key="idx"
              :data-idx="idx"
            >放在这里</div>

            <div
              v-else
              :class="['tpl-container', comp.active ? 'current' : '']"
              :key="idx"
              :data-idx="idx"
              @click.capture="clickComp"
            >
              <!-- {{comp.type}} -->
              <!--图片控件-->
              <base-image v-if="comp.type === 'img'" :component="comp"></base-image>
              <!--轮播图控件-->
              <app-swiper v-if="comp.type === 'app-swiper'" :component="comp"></app-swiper>
              <split-image v-if="comp.type === 'split-image'" :component="comp"></split-image>
              <hot-sale v-if="comp.type === 'hotSale'" :component="comp"></hot-sale>
              <douyin-hot-sale v-if="comp.type === 'douyinHotSale'" :component="comp"></douyin-hot-sale>
              <high-commission v-if="comp.type === 'highCommission'" :component="comp"></high-commission>
              <high-discount v-if="comp.type === 'highDiscount'" :component="comp"></high-discount>
              <recommend-goods v-if="comp.type === 'recommendGoods'" :component="comp"></recommend-goods>
              <king-kong v-if="comp.type === 'king-kong'" ref="kingKongCom" :component="comp"></king-kong>
              <v-goods-bang v-if="comp.type === 'v-goods-bang'" :component="comp"></v-goods-bang>

              <!--控件操作-->
              <div class="comp-menu">
                <a
                  href="javascript:void(0)"
                  :class="[idx == 1 ? 'disabled' : '']"
                  @click="upComp(idx)"
                  v-if="comp.type !== 'recommendGoods'"
                >
                  <span class="tips">上移</span>
                  <i class="fa fa-arrow-circle-up"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  :class="[idx == compList.length - 2 ? 'disabled' : '']"
                  @click="downComp(idx)"
                  v-if="comp.type !== 'recommendGoods' && idx !== compList.length - 3"
                >
                  <span class="tips">下移</span>
                  <i class="fa fa-arrow-circle-down"></i>
                </a>
                <a href="javascript:void(0)" @click="delComp(idx)" v-if="!isDefaultComponents">
                  <span class="tips">删除</span>
                  <i class="fa fa-trash"></i>
                </a>
              </div>
            </div>
          </template>
        </div>

        <!--底部导航控件-->
        <div
          v-if="bottomMenu"
          :class="['absolute-tpl', bottomMenu.active ? 'current' : '']"
          @click="clickBtmMenu"
        >
          <bottom-menu :component="bottomMenu"></bottom-menu>
          <div class="comp-menu">
            <a href="javascript:void(0)" @click="delBtmMenu">
              <span class="tips">删除</span>
              <i class="fa fa-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <preview-dialog :show.sync="previewShow" :compList="compList"></preview-dialog>

    <app-opt ref="appOption" v-if="currentConfig" :option="currentConfig"></app-opt>
    <app-page-opt v-else :option="pageConfig"></app-page-opt>

    <!-- <click-config
      :show.sync="clickShow"
      :option="currentConfig"
      :comps="compList"
      :index="click.index"
      :tabs="click.tabs"
    ></click-config>-->
  </div>
</template>

<script>
import Vue from 'vue';
import Components from "@/components/h5maker";
import "normalize.css/normalize.css";
// import ElementUI from "element-ui";
import { Input } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "font-awesome/css/font-awesome.min.css";
Vue.use(Components);
Vue.use(Input);
import util from "@/utils/h5-marker-util.js";
import appSidebar from "@/components/h5maker/views/layout/sidebar.vue";
import appToolbar from "@/components/h5maker/views/layout/apphome.toolbar.vue";
import appOpt from "@/components/h5maker/views/layout/option.vue";
import appPageOpt from "@/components/h5maker/views/layout/apphome.pageOption.vue";
import { setEditStatus } from "@/utils/index.js";
// import clickConfig from "@/components/h5maker/common/jump.vue";
import previewDialog from "@/components/h5maker/common/home.preview.vue";
// 页面默认配置
import pageOption from "@/config/app.home.config.js";
// 组件默认配置
import compConfig from "@/config/app.comp.config.js";

import { patchCustomModule, getCustomModule } from "@/service/operate";
// import { getCustomModule } from "@/service/operate";
import { checkPer } from "@/utils/index";

export default {
  name: "AppHomeMain",
  components: {
    appSidebar,
    appToolbar,
    appOpt,
    appPageOpt,
    // clickConfig,
    previewDialog
  },
  data() {
    return {
      isPageLoaded: false,
      clickShow: false,
      previewShow: false,
      tempData: null, // 临时数据
      backendData: null, // 已保存数据
      click: {
        index: 0,
        tabs: []
      },
      compList: [
        {
          type: "placeholder"
        }
      ],
      bottomMenu: null,
      pageConfig: util.copyObj(pageOption),
      currentIndex: -1,
      currentConfig: null
    };
  },
  beforeRouteLeave(to, from, next) {
    const tempDataStr = JSON.stringify(this.tempData);
    const backendDataStr = JSON.stringify(this.backendData);
    if (tempDataStr !== backendDataStr) {
      // 保存数据和当前数据不相同
      setTimeout(() => {
        this.$confirm("您的数据尚未保存，是否离开？", "离开页面", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            next();
          })
          .catch(() => {
            next(false);
          });
      }, 200);
    } else {
      next();
    }
  },
  mounted() {
    setEditStatus("false");
    window.onbeforeunload = function(e) {
      e = e || window.event;

      // const _f = false

      // const tempDataStr = JSON.stringify(this.tempData);
      // const backendDataStr = JSON.stringify(this.backendData);
      // if (_f===true) {
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "关闭提示";
      }
      return "关闭提示";
      // } else {
      //   return null
      // }
    };

    this.$bus.$on("click:show", (idx, tabs) => {
      this.click.index = idx;
      if (Array.isArray(tabs) && tabs.length) this.click.tabs = tabs;
      else this.click.tabs = ["outside", "page", "tel"];
      this.clickShow = true;
    });
    this.$bus.$on("click:submit", (idx, config) => {
      if (idx > -1 && config) {
        if (this.currentIndex >= 0) {
          this.compList[this.currentIndex].action.config[idx].click = config;
        } else if (this.currentIndex === -2) {
          // 底部导航栏点击配置
          this.bottomMenu.action.config[idx].click = config;
        }
      }
    });
    // this.readLocalData();
    this.getHomeConfigList();
    this.showPageSet();
  },
  computed: {
    isDefaultComponents: function() {
      const _type = this.currentConfig && this.currentConfig["type"];
      const isDefault = [
        "douyinHotSale",
        "hotSale",
        "highDiscount",
        "highCommission",
        "recommendGoods"
      ].includes(_type);
      return isDefault;
    }
  },
  watch: {
    compList: {
      handler(val) {
        if (val && val.length > 1) {
          localStorage.setItem(
            "pageDateSet",
            JSON.stringify({
              time: Date.now(),
              menu: this.bottomMenu,
              config: val
            })
          );
        }
      },
      deep: true
    },
    bottomMenu: {
      handler(val) {
        localStorage.setItem(
          "pageDateSet",
          JSON.stringify({
            time: Date.now(),
            menu: val,
            config: this.compList
          })
        );
      }
    }
  },
  // 页面卸载
  destroyed() {
    window.onbeforeunload = null;
  },
  methods: {
    // 页面关闭后退前判断是否有数据没保存
    checkIsSaved(e) {
      alert(e);
      const tempDataStr = JSON.stringify(this.tempData);
      const backendDataStr = JSON.stringify(this.backendData);
      if (backendDataStr === tempDataStr) {
        alert("无需保存");
      } else {
        alert("需要保存数据");
        const saveDataConfirm = window.confirm("数据未保存是否需要保存数据");
        console.log(saveDataConfirm);
      }
    },
    showPageSet() {
      this.resetCompUnchecked();
      this.currentIndex = -1;
      this.currentConfig = null;
    },
    // 判断当前项是否有没有填写到
    checkCurrentBlock() {
      let isAllPassed = true;
      if (
        this.currentConfig !== null &&
        this.currentConfig.type !== "v-goods-bang"
      ) {
        if (!this.isDefaultComponents) {
          // 非内置组件
          isAllPassed = this.currentConfig.action["config"].every(function(i) {
            let jumpCheck = false;
            if (i.type === "none") {
              jumpCheck = true;
            } else {
              jumpCheck = i.value;
            }
            return jumpCheck && i.width && i.imgUrl;
          });
        } else {
          isAllPassed = true;
        }
      } else {
        isAllPassed = true;
      }
      return isAllPassed;
    },
    // 判断是否有errorMsg
    hasErrorMsg() {
      if (this.currentConfig) {
        const currentBlock = JSON.parse(JSON.stringify(this.currentConfig));
        if (
          ["app-swiper", "split-image", "king-kong", "v-goods-bang"].includes(
            currentBlock.type
          )
        ) {
          const hasError = currentBlock.action.config.some(
            i => i.hasOwnProperty("errorMsg") && i.errorMsg !== null
          );
          return hasError;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 验证图片数量
    hasImgNumError() {
      if (
        this.currentConfig &&
        this.currentConfig.base &&
        this.currentConfig.type === "split-image"
      ) {
        const split = this.currentConfig.base[0]["val"];
        const items = this.currentConfig.action.config;
        // 图片个数
        const imgsCount = items.reduce(function(prev, current) {
          if (current.imgUrl && current.imgUrl.length) {
            return prev + 1;
          } else {
            return prev;
          }
        }, 0);

        if (imgsCount % split !== 0) {
          return true;
        }
      }
      return false;
    },
    // 验证外露表单是否有字段没有填写
    checkIfVGoodsBangFilled() {
      if (
        this.currentConfig &&
        this.currentConfig.base &&
        this.currentConfig.type === "v-goods-bang"
      ) {
        const title = this.currentConfig.base[0]['val'];
        const desc = this.currentConfig.base[1]['val'];
        if(title.length === 0 || desc.length === 0){
          return true
        }
      }
      return false;
    },
    // 验证金刚位数量是否超过标准
    checkIfKingKongOver() {
      if (
        this.currentConfig &&
        this.currentConfig.base &&
        this.currentConfig.type === "king-kong"
      ) {
        const split = this.currentConfig.base[0]["val"];
        const items = this.currentConfig.action.config;
        if(split ===4 && items.length > 8){
          return true
        }
      }
      return false;
    },
    // 保存页面数据
    async savePageSet() {
      if (this.checkIfVGoodsBangFilled()) {
        this.$message.error("标题文案和描述文案为必填项！");
        return false;
      }

      if (this.hasImgNumError()) {
        this.$message.error("图片数量须为等分图的倍数！");
        return false;
      }
      // 判断区块是否有没填写的字段
      const passed = await this.checkIsAllFill();
      if (!passed) {
        this.$message.error(
          "当前模块还有未完善的内容，请先完善后再操作其他模块！"
        );
        return false;
      }
      if (!this.checkCurrentBlock() || this.hasErrorMsg()) {
        this.$message.error("信息未填写完整，请检查！");
        return false;
      }
      // 检查金刚位的个数是否超标
      if(this.checkIfKingKongOver()){
          this.$message.error("金刚位图片数量超标，单排4个时，图片最多8个，单排5个时，图片最多10个！");
          return false;
        }

      const setting = JSON.parse(JSON.stringify(this.compList));
      setting.forEach(i => {
        i.active = false;
        return i;
      });
      const data = {
        // backend_settings: JSON.stringify(this.backend), // 重置
        backend_settings: JSON.stringify(setting),
        frontend_settings: JSON.stringify(this.filterItem(setting)),
        id: this.$route.query.id
      };

      const { err, res } = await patchCustomModule(data);
      if (!err) {
        if (res.success) {
          this.tempData = this.compList;
          this.backendData = this.compList;
          this.$message({
            message: "保存成功",
            type: "success"
          });
          setEditStatus("false");
        }
      }
    },
    // 获取配置信息
    async getHomeConfigList() {
      if (!checkPer("operate_manage_modify_custom")) {
        this.$message.error("无权限获取数据");
        return false;
      }
      const params = {
        id: this.$route.query.id,
        extra_fields: "backend_settings"
      };
      this.listLoading = true;
      const { err, res } = await getCustomModule(params);
      this.listLoading = false;
      this.isPageLoaded = true;
      if (!err) {
        if (res.success && res.data.count) {
          const backendData = JSON.parse(
            JSON.stringify(res.data.results[0].backend_settings)
          );
          this.compList = backendData;
          this.backendData = backendData;
          this.tempData = backendData;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 过滤前端不需要到字段
    filterItem(json) {
      // 过滤不需要的filter
      const frontendArr = json.filter(i => i.type !== "placeholder");
      // 将action值放到外部
      const formatComponents = frontendArr.map(i => {
        let dc = {};
        if (
          [
            "douyinHotSale",
            "hotSale",
            "highDiscount",
            "highCommission",
            "recommendGoods"
          ].includes(i.type)
        ) {
          dc.type = i.type;
          dc.isShow = i.action.config.isShow;
        }

        if (i.type === "app-swiper") {
          dc = this.exSwiperDataToFront(i);
        }

        if (i.type === "split-image") {
          dc = this.exSplitImageDataToFront(i);
        }

        if (i.type === "king-kong") {
          dc = this.exKingkongDataToFront(i);
        }

        if (i.type === "v-goods-bang") {
          dc = this.exVGoodsDataToFront(i);
        }

        return dc;
      });

      // 过滤掉isShow=false的
      const finnalComponsts = formatComponents.filter(
        i =>
          ![
            "douyinHotSale",
            "hotSale",
            "highDiscount",
            "highCommission",
            "recommendGoods"
          ].includes(i.type) ||
          ([
            "douyinHotSale",
            "hotSale",
            "highDiscount",
            "highCommission",
            "recommendGoods"
          ].includes(i.type) &&
            i.isShow === true)
      );

      // const frontendStr = JSON.stringify(finnalComponsts);
      return finnalComponsts;
    },
    // 后台图片数据转前台图片数据
    exSplitImageDataToFront(data) {
      let res = {
        type: "split-image",
        width: data.action.config[0]["width"],
        height: data.action.config[0]["height"],
        split: data.base[0].val,
        items: data.action.config
      };
      return res;
    },
    // 后台swiper数据转前台数据
    exSwiperDataToFront(data) {
      let res = {
        type: "app-swiper",
        width: data.action.config[0]["width"],
        height: data.action.config[0]["height"],
        items: data.action.config
      };
      return res;
    },
    // 后台金刚位数据转前台数据
    exKingkongDataToFront(data) {
      let res = {
        type: "king-kong",
        colorValue: data.style[0]["val"],
        width: data.action.config[0]["width"],
        height: data.action.config[0]["height"],
        split: data.base[0].val,
        items: data.action.config
      };
      return res;
    },
    // 榜单商品数据转前台数据
    exVGoodsDataToFront(data) {
      let res = {
        type: "v-goods-bang",
        title: data.base[0].val,
        desc: data.base[1].val,
        jumpTo: {
          type: data.base[2] && data.base[2].type,
          value: data.base[2] && data.base[2].value
        },
        items: data.action.config
      };
      return res;
    },
    showPreview() {
      localStorage.setItem("pageConfig", JSON.stringify(this.pageConfig));
      this.previewShow = true;
    },
    resetCompUnchecked() {
      if (this.bottomMenu) this.bottomMenu.active = false;
      this.compList.forEach(val => {
        if (val.active) {
          val.active = false;
        }
      });
    },
    replacePlaceholderWithComp(index, key) {
      const comp = util.copyObj(compConfig[key]);
      const config = {
        type: key,
        active: true,
        domId: key + "-" + util.createDomID()
      };
      Object.assign(comp, config);
      this.compList.splice(index + 1, 0, comp);
      // 再插入一个占位控件
      this.compList.splice(index + 2, 0, {
        type: "placeholder"
      });
      // 显示配置项
      this.currentIndex = index + 1;
      this.currentConfig = comp;
    },
    addBottomMenu() {
      const comp = util.copyObj(compConfig["bottom-menu"]);
      const config = {
        type: "bottom-menu",
        active: true,
        domId: "bottom-menu-" + util.createDomID()
      };
      Object.assign(comp, config);
      this.bottomMenu = comp;
      // 显示配置项
      this.currentIndex = -1;
      this.currentConfig = comp;
    },
    // 选择块
    async clickComp(e) {
      if (this.bottomMenu) this.bottomMenu.active = false;
      const idx = parseInt(e.currentTarget["dataset"]["idx"]); // 点击模块index
      const _idx = this.compList.findIndex(i => i.active === true); // 选中模块index

      if (idx !== _idx) {
        if (this.checkIfVGoodsBangFilled()) {
        this.$message.error("标题文案和描述文案为必填项！");
        return false;
      }

        // 判断区块是否有没填写的字段
        if (!this.checkCurrentBlock()) {
          this.$message.error(
            "当前模块还有未完善的内容，请先完善后再操作其他模块！"
          );
          return false;
        }
        // 判断区块是否有没填写的字段
        const res = await this.checkIsAllFill();
        if (!res) {
          this.$message.error(
            "当前模块还有未完善的内容，请先完善后再操作其他模块！"
          );
          return false;
        }

        if (this.hasImgNumError()) {
          this.$message.error("图片数量须为等分图的倍数！");
          return false;
        }

        if(this.checkIfKingKongOver()){
          this.$message.error("金刚位图片数量超标，单排4个时，图片最多8个，单排5个时，图片最多10个！");
          return false;
        }
      }

      this.compList.forEach((val, index) => {
        if (index === idx) {
          val.active = true;
          this.currentIndex = index;
          this.currentConfig = val;
        } else {
          val.active = false;
        }
      });
    },
    clickBtmMenu() {
      this.resetCompUnchecked();
      if (this.bottomMenu) this.bottomMenu.active = true;
      this.currentIndex = -2;
      this.currentConfig = this.bottomMenu;
    },
    delBtmMenu() {
      this.bottomMenu = null;
      // 显示页面配置参数
      this.showPageSet();
    },
    upComp(idx) {
      if (idx < 2) {
        return false;
      }
      // 复制当前控件
      const tmp = util.copyObj(this.compList[idx]);
      // 再删除控件+占位坑
      this.compList.splice(idx, 2);
      // 再插入控件
      this.compList.splice(idx - 2, 0, tmp);
      // 最后插入一个占位控件
      this.compList.splice(idx - 1, 0, {
        type: "placeholder"
      });
      // 显示当前组件配置
      this.currentIndex = idx - 2;
      this.currentConfig = this.compList[idx - 2];
    },
    downComp(idx) {
      if (idx === this.compList.length - 2) {
        return false;
      }
      // 复制当前控件
      const tmp = util.copyObj(this.compList[idx]);
      // 再删除控件+占位坑
      this.compList.splice(idx, 2);
      // 再插入控件
      this.compList.splice(idx + 2, 0, tmp);
      // 最后插入一个占位控件
      this.compList.splice(idx + 3, 0, {
        type: "placeholder",
        active: false
      });
      // 显示当前组件配置
      this.currentIndex = idx + 2;
      this.currentConfig = this.compList[idx + 2];
    },
    delComp(idx) {
      // 删除控件
      this.compList.splice(idx, 2);
      // 显示页面配置参数
      this.showPageSet();
    },
    dragover(e) {
      const target = e.target;
      if (!target.classList.contains("active")) target.classList.add("active");
    },
    async drop(e) {
      const target = e.target;
      target.classList.remove("active");
      // 判断区块是否有没填写的字段
      if (!this.checkCurrentBlock()) {
        this.$message.error(
          "上一个模块还有未完善的内容，请先完善后再操作其他模块！"
        );
        return false;
      }
       if (this.hasImgNumError()) {
        this.$message.error("图片数量须为等分图的倍数！");
        return false;
      }
      // 判断区块是否有没填写的字段
      const res = await this.checkIsAllFill();
      if (!res) {
        this.$message.error(
          "当前模块还有未完善的内容，请先完善后再操作其他模块！"
        );
        return false;
      }
      const key = e.dataTransfer.getData("cmp-type");
      if (key === "bottom-menu") return;

      const idx = parseInt(target.dataset.idx);

      if (compConfig[key]) {
        this.resetCompUnchecked();
        this.replacePlaceholderWithComp(idx, key);
      } else {
        this.$message.warning("没有查询到该组件的配置信息。。。");
      }
    },
    dragleave(e) {
      e.target.classList.remove("active");
    },
    dragPhoneOver() {
      const target = document.querySelector(".place-holder:last-child");
      if (target && !target.classList.contains("active"))
        target.classList.add("active");
    },
    dropPhone(e) {
      const target = document.querySelector(".place-holder:last-child");
      if (target) {
        target.classList.remove("active");
        const key = e.dataTransfer.getData("cmp-type");
        const idx = parseInt(target.dataset.idx);
        if (compConfig[key]) {
          if (key === "bottom-menu") {
            if (this.bottomMenu) {
              this.$message.info("已经存在一个底部导航组件了，请勿重复添加！");
            } else {
              this.addBottomMenu();
            }
          } else {
            this.resetCompUnchecked();
            this.replacePlaceholderWithComp(idx, key);
          }
        } else {
          this.$message.warning("没有查询到该组件的配置信息。。。");
        }
      }
    },
    dragPhoneLeave() {
      const target = document.querySelector(".place-holder:last-child");
      target && target.classList.remove("active");
    },
    // 检测必填项
    async checkIsAllFill() {
      if (!this.currentConfig) return true;
      let res = false;
      try {
        res = await this.$refs.appOption.validateAllAppOptions();
      } catch (error) {
        res = false;
      }
      return res;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.app-home-d-block {
  text-align: center;
  padding: 0 15px;
  .default-c-box {
    img {
      display: block;
      width: 100%;
    }
  }
}
.app-main {
  position: relative;
  flex: 1;
  min-width: 377px;
  background-color: #ffffff;

  .scroll-y {
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-y: auto;
    background-color: #f5f5f5;
  }

  .app-phone {
    position: relative;
    box-sizing: border-box;
    width: 377px;
    min-height: 667px;
    padding-bottom: 200px;
    margin: 0 auto;
    background-color: #f4f6f6;

    .place-holder {
      position: relative;
      display: block;
      height: 10px;
      text-align: center;
      font-size: 0;
      background: #fff;
      background-image: linear-gradient(#fff 2px, transparent 0),
        linear-gradient(90deg, #999 1px, transparent 0);
      background-size: 3px 3px, 3px 3px;

      &.active {
        height: 40px;
        line-height: 40px;
        border: 1px dashed #f32e37;
        background-color: #ffe800;
        background-image: none;
        color: #333;
        font-size: 14px;
        margin: 0;
      }
    }

    .tpl-container {
      position: relative;
      width: 100%;
      background-color: transparent;
      position: relative;
      border: 1px solid #f2f3f4;
      transition: opacity 0.1s ease-out;
      user-select: none;
      box-sizing: border-box;
      z-index: auto;
      opacity: 1;
      cursor: pointer;

      &.current {
        border: 1px solid #2aa7ff;

        .comp-menu {
          display: block;
        }
      }

      .comp-content {
        position: relative;
      }
    }
  }

  .comp-menu {
    position: absolute;
    display: none;
    top: 0;
    right: -38px;
    width: 32px;

    > a {
      display: inline-block;
      position: relative;
      width: 36px;
      height: 36px;
      margin-bottom: 2px;
      text-align: center;
      color: #666;
      background: #fff;
      font-size: 18px;
      line-height: 36px;

      &.disabled {
        color: #e4e4e4;
      }

      &:hover {
        &.disabled {
          color: #e4e4e4;
        }
        color: #333;
        .tips {
          display: block;
        }
      }

      .tips {
        font-size: 12px;
        position: absolute;
        left: 120%;
        background-color: #111;
        height: 18px;
        padding: 0 5px;
        line-height: 19px;
        border-radius: 2px;
        white-space: nowrap;
        display: none;
        color: #eee;
        top: 5px;

        &::before {
          content: " ";
          display: block;
          position: absolute;
          left: -5px;
          top: 4.5px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 6px 5px 0;
          border-color: transparent #111 transparent transparent;
        }
      }
    }
  }

  .absolute-tpl {
    width: 377px;
    margin: 0 auto;
    transform: translateX(-20px);
    border-top: 1px solid #e8e8e8;

    &.current {
      border: 1px solid #2aa7ff;

      .comp-menu {
        display: block;
      }
    }
  }
}
.block-hide {
  opacity: 0.3;
}
</style>
