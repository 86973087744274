/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-08 11:00:34
 * @LastEditTime: 2020-05-11 11:16:58
 */
module.exports = {
  title: '页面配置',
  
}
