<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-08 10:51:53
 * @LastEditTime: 2020-05-26 21:00:16
 -->
<template>
  <div id="h5Maker">
    <app-header type="apphome"></app-header>
    <app-home-main></app-home-main>
  </div>
</template>

<script>
import Vue from 'vue';
import {Modal} from 'ant-design-vue';
import Components from "@/components/h5maker";
import "normalize.css/normalize.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "font-awesome/css/font-awesome.min.css";
Vue.use(Components);
Vue.use(ElementUI, { size: "small" });

Vue.prototype.$confirm = Modal.confirm; // 重新替换一下

import appHeader from "@/components/h5maker/views/layout/header.vue";
import appHomeMain from "@/components/h5maker/views/home-main.vue";
export default {
  name: "homeMaker",
  components: {
    appHeader,
    appHomeMain
  }
};
</script>
<style lang="scss">
body {
  margin: 0px;
  padding: 0px;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, SimSun, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

#app {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  min-width: 1000px;

  .app-body {
    display: flex;
    position: absolute;
    top: 60px;
    bottom: 0;
    width: 100%;
  }
}
</style>